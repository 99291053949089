import './src/styles/global.css';
import { WOW } from 'wowjs/dist/wow.js';

export const onRouteUpdate = ({ location, prevLocation }) => {
  new WOW().init();
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }
  if (!prevLocation && !location.hash) {
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0);
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  window.history.scrollRestoration = 'manual';
  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    if (location.hash) {
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: 'instant',
      });
    }
  }, 0);

  return false;
};
